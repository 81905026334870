import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { useEventPage } from './hook';
import BluredBackgroundImage from '../../../Components/EventComponents/BluredBackgroundImage';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import Checkout from '../../../Components/Checkout/Checkout';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import EventTicketsCard from '../../../Components/Cards/EventTicketsCard/EventTicketsCard';
import StadiumImage from '../../../Components/StadiumImage/StadiumImage';
import Button from '../../../Components/Buttons/Button/Button';
import EventHeader from './EventHeader/EventHeader';
import EventDisclaimer from './EventDisclaimer/EventDisclaimer';
import MoreEvents from './MoreEvents/MoreEvents';

const EventPage = ({ classes, setTheme }) => {
  const {
    event,
    seller,
    eventPromotions,
    eventTickets,
    isSellerValid,
    isLoading,
    stadium,
    country,
    cart,
    isCheckoutOpen,
    openCheckout,
    closeCheckout,
    getInitialData,
    updateTicketInCart,
    incrementTicketQuantity,
    decrementTicketQuantity,
  } = useEventPage(setTheme);
  const { user } = useAuth();
  const { showNotification } = useNotifications();

  if (isLoading)
    return (
      <PageContainer withFooter showSearchButton>
        <CustomCircularProgress size={40} />
      </PageContainer>
    );

  if (!isSellerValid)
    return (
      <PageContainer withFooter title={event.name} country={country} showSearchButton>
        <BluredBackgroundImage bannerImg={event.bannerImg} />
        <Stack className={classes.notFoundSellerText}>
          <VTypography align="center" variant="subtitle2">
            El link ingresado no es válido o el RRPP no se encuentra activo en este evento.
          </VTypography>
          <VTypography align="center" variant="subtitle2">
            Si cree que esto es un error, verifique nuevamente el enlace con el promotor.
          </VTypography>
          <Button
            variant="outlined"
            title="Volver al inicio"
            fullwidth={false}
            target="_self"
            href="/"
          />
        </Stack>
      </PageContainer>
    );

  return (
    <PageContainer
      withFooter
      title={event.name}
      country={country}
      withPaddingTop={false}
      showSearchButton
    >
      <BluredBackgroundImage bannerImg={event.bannerImg} />
      <Stack width="90%" alignSelf="center">
        <EventHeader event={event} seller={seller} eventPromotions={eventPromotions} />
        <Stack width={{ xs: '100%', md: '75%' }} className={classes.contentContainer}>
          {stadium && <StadiumImage image={stadium.image} />}
          <Checkout
            handleOpen={openCheckout}
            open={isCheckoutOpen}
            handleClose={closeCheckout}
            seller={seller}
            cart={Object.values(cart)}
            addItemToCart={incrementTicketQuantity}
            deleteItemFromCart={decrementTicketQuantity}
            tickets={eventTickets}
            loggedUser={user}
            event={event}
            showNotification={showNotification}
            refreshEvent={getInitialData}
          />
          <Stack className={classes.ticketsContainer}>
            {eventTickets.map((ticket) => (
              <EventTicketsCard
                currentValue={cart[ticket.id]?.quantity}
                key={ticket.id}
                addToCart={updateTicketInCart}
                ticket={ticket}
              />
            ))}
          </Stack>
          <Stack
            direction="row"
            justifyContent={{ xs: 'center', sm: 'left' }}
            className={classes.descriptionContainer}
          >
            {event.promoImg && (
              <img alt="" className={classes.descriptionImage} src={event.promoImg} />
            )}
            {event.description && (
              <Stack>
                <VTypography variant="h3">Descripción</VTypography>
                {event.description.split('\n').map((line, i) => (
                  <VTypography variant="body1" key={`line-${i}`}>
                    {line}
                  </VTypography>
                ))}
              </Stack>
            )}
          </Stack>
          <MoreEvents event={event} />
          <EventDisclaimer country={country} />
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(EventPage);
